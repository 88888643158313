import instance from './axios.js'

// 解锁订单列表
export const releaseOrderList = (para)=>{
  return instance.get('/case/releaseOrderList',{params:para})
}
// 保证金订单列表
export const depositOrderList = (para)=>{
  return instance.get('/case/depositOrderList',{params:para})
}
// 订单详情
export const orderDetail = (para)=>{
  return instance.get('/case/orderDetail',{params:para})
}
// 订单退款
export const refund = (para)=>{
  return instance.post('/case/refund',para)
}