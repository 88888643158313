<template lang="html">
<el-dialog
  title="支付详情"
  :visible.sync="dialogVisible"
  width="50%">
  <div>
  	<el-descriptions title="招标信息" :column="2">
		    <el-descriptions-item label="招标ID">{{info.caseId}}</el-descriptions-item>
		    <el-descriptions-item label="招标状态">{{targetStatus[info.caseStatus]}}</el-descriptions-item>
		    <el-descriptions-item label="发布主体">{{info.publisherName}}</el-descriptions-item>
		    <el-descriptions-item label="截止投标">{{info.deadline}}</el-descriptions-item>
		</el-descriptions>
		<el-descriptions title="支付信息" :column="2">
		    <el-descriptions-item label="支付人ID">{{info.payAccountId}}</el-descriptions-item>
		    <el-descriptions-item label="支付人">{{info.payAccountName}}</el-descriptions-item>
		    <el-descriptions-item label="资格解锁金">{{info.payAmount}}</el-descriptions-item>
		    <el-descriptions-item label="解锁金订单号">{{info.payOrderNo}}</el-descriptions-item>
		    <el-descriptions-item label="商户订单号">{{info.orderNo}} </el-descriptions-item>
		    <el-descriptions-item label="支付情况"> {{payStatusOptions[info.payStatus]}}</el-descriptions-item>
		    <el-descriptions-item label="支付时间">{{info.payTime}}</el-descriptions-item>
		    <el-descriptions-item label="字符失败原因"></el-descriptions-item>
		</el-descriptions>
		<el-descriptions title="投标信息">
		    <el-descriptions-item label="是否投标">{{info.caseSubmitFlag == 1?'是':'否'}}</el-descriptions-item>
		    <el-descriptions-item label="投标ID">{{info.caseSubmitId}}</el-descriptions-item>
		    <el-descriptions-item label="投标时间">{{info.submitTime}}</el-descriptions-item>
		    
		</el-descriptions>
		<!-- <el-descriptions title="退款信息">
		    <el-descriptions-item label="退款原因"></el-descriptions-item>
		    <el-descriptions-item label="退款情况"></el-descriptions-item>
		    <el-descriptions-item label="退款时间"></el-descriptions-item>
		    <el-descriptions-item label="退款失败原因"></el-descriptions-item>
		</el-descriptions> -->
  </div>
  <span slot="footer" class="dialog-footer">
    <el-button @click="cancel">关 闭</el-button>
  </span>
</el-dialog>
</template>

<script>
import {mapState} from 'vuex'
    export default {
    	data(){
    		return{
    			dialogVisible:false,
    			form:{
    				value:''
    			}
    		}
    	},
      props:['info'],
    	components:{},
      computed:{
        ...mapState({
            payStatusOptions: state => state.dictionary.payStatus,
            targetStatus: state => state.dictionary.targetStatus
          }),
      },
    	methods:{
    		cancel(){
    			this.dialogVisible = false
    		}
    	}
    }
</script>

<style scoped lang="scss">
	h3{
		font-size:18px;
	}
.el-row{
	margin:12px 0;
	.el-col{
		display:flex;
		align-items:center;
		label{
			display:inline-block;
			width:90px;
			font-weight:500;
		}
	}
}
</style>